import request from '@/utils/request';

// 获取丽日商品列表
export function getProcudtList (data) {
    return request({
        url: '/goods-service/goods/info/list',
        method: 'post',
        data
    })
}

// 获取丽日门店列表
export function getStoreList () {
    return request({
        url: '/goods-service/store/listOfPage',
        method: 'get'
    })
}

// 获取丽日商品品类列表
export function getTypeList (data) {
    return request({
        url: '/goods-service/goods/queryShopGoodsType',
        method: 'post',
        data
    })
}

// 获取丽日商户列表
//接口：商户管理查询
//地址：http://${BASE_URL}/customer-service/lrMerchant/queryMerchant
export function getMerchantsList (data = {}) {
    return request({
        url: '/customer-service/lrMerchant/queryMerchant',
        method: 'post',
        data
    })
}
//接口：门店下的商户信息(不分页）
   // 地址：http://${BASE_URL}/customer-service/lrMerchant/queryMerchantByShopAll
   export function getMerchantsListByStoreId (data = {}) {
    return request({
        url: '/customer-service/lrMerchant/queryMerchantByShopAll',
        method: 'post',
        data
    })
}


//接口：查询餐饮类别下的商户
//地址：http://${BASE_URL}/customer-service/lrMerchant/queryCateringMerchant?storeId=#{storeId}
export function getCateringMerchantByStoreId (data) {
    return request({
        url: `/customer-service/lrMerchant/queryCateringMerchant?storeId=${data}`,
        method: 'get'
    })
}


// 获取邮寄模板列表
export function getShippingList (data) {
    return request({
        url: '/customer-service/api/lrShippingTemplates/queryPage',
        method: 'post',
        data
    })
}

//  根据id获取丽日商品详情信息
export function getProductDetail (id) {
    return request({
        url: "/goods-service/goods/info/" + id,
        method: 'get'
    })
}

//  添加、编辑丽日商品提交
export function subProduct (data) {
    return request({
        url: "/goods-service/goods/addGoodsData",
        method: 'post',
        data
    })
}

//  编辑丽日商品排序
export function subSort (data) {
    return request({
        url: "/goods-service/goods/sort",
        method: 'put',
        data
    })
}

//  丽日商品上架
export function productUpShelf (id) {
    return request({
        url: "/goods-service/goods/info/up/" + id,
        method: 'put'
    })
}

//  丽日商品下架
export function productDownShelf (id) {
    return request({
        url: "/goods-service/goods/info/down/" + id,
        method: 'put'
    })
}

//  丽日商品删除
export function productDelete (id) {
    return request({
        url: "/goods-service/goods/info/" + id,
        method: 'delete'
    })
}

//  生成规格
export function generateSpecification (data) {
    return request({
        url: "/goods-service/goods/generateGoodsInfo",
        method: 'post',
        data
    })
}
